import {
  Establishment,
  getEstablishmentFeaturesByPlan,
  getCurrencyLocaleDataByCountryCode,
  TypesOfShippings,
  SubscriptionInfo,
  firestoreDateParser,
  WorkingDays,
  Feature,
  DeliveryType,
  PaymentMethod,
  EstablishmentCheckout,
} from '@pedix-workspace/utils';
import { FirestoreNestedConverter, withNestedConverter } from './utils';

export type EstablishmentConverterParams = {
  nestedConverter?: FirestoreNestedConverter<Establishment>;
};

export const DEFAULT_PRODUCT_EXPORTS: Establishment['productsExport'] = {
  enabled: false,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sources: [{ type: 'instagram', enabled: false } as any],
};

// TODO: REMOVE
const DEFAULT_SHIPPING_OPTIONS: EstablishmentCheckout['shippingOptions'] = [
  TypesOfShippings.DELIVERY,
  TypesOfShippings.TAKE_AWAY,
];

export const getEstablishmentConverter = ({ nestedConverter }: EstablishmentConverterParams = {}) =>
  withNestedConverter({
    fromFirestore(snapshot) {
      return convertEstablishmentData(snapshot.id, <Establishment>snapshot.data());
    },
    toFirestore(establishment: Establishment): Establishment {
      return establishment;
    },
  })(nestedConverter);

export const convertEstablishmentData = (
  establishmentId: string,
  establishmentData: Partial<Establishment>,
): Establishment => {
  const plan = establishmentData.subscription?.plan || '';
  const features = getEstablishmentFeaturesByPlan(plan, establishmentData?.features);
  const currencyCode =
    establishmentData.currencyCode || getCurrencyLocaleDataByCountryCode().currencyCode;

  return <Establishment>{
    ...establishmentData,
    id: establishmentId,
    features: features,
    currencyCode,
    userIds: establishmentData.userIds || {},
    contactInfo: establishmentData.contactInfo || {},
    links: establishmentData.links || {},
    deliveryZones: establishmentData['deliveryZones'] || [],
    minimumPurchase: establishmentData.minimumPurchase || 0,
    deliveryCost:
      establishmentData['deliveryCost'] === undefined ? 0 : establishmentData['deliveryCost'],
    addressDetails: establishmentData.addressDetails,
    outOfDeliveryZoneShippingAllowed:
      establishmentData['outOfDeliveryZoneShippingAllowed'] || false,
    shippingOptions: establishmentData['shippingOptions'] || DEFAULT_SHIPPING_OPTIONS,
    productsExport: establishmentData.productsExport || DEFAULT_PRODUCT_EXPORTS,
    maxMultimediaRepositorySize: establishmentData.maxMultimediaRepositorySize || 5,
    requireAddressDetails: getRequireAddressDetails(
      features,
      establishmentData['requireAddressDetails'],
    ),
    paymentMethods: getPaymentMethods(establishmentData['paymentMethods']),
    enableCoupons: getEnableCoupons(features, establishmentData.enableCoupons) || false,
    isDigitalMenu: getIsDigitalMenu(features, establishmentData.isDigitalMenu),
    subscription: getSubscriptionInfo(establishmentData.subscription),
    workingDays: getWorkingDays(features, establishmentData.workingDays),
    temporaryClosure: getTemporaryClosure(features, establishmentData.temporaryClosure),
    customFields: getCustomFields(features, establishmentData['customFields']),
    deliveryType: getDeliveryType(features, establishmentData['deliveryType']),
    facebookPixel: getFacebookPixel(features, establishmentData.facebookPixel),
    googleAnalytics: getGoogleAnalytics(features, establishmentData.googleAnalytics),
    googleTagManager: getGoogleTagManager(features, establishmentData.googleTagManager),
    seoSocialMedia: getSeoSocialMedia(features, establishmentData.seoSocialMedia),
    theme: getTheme(features, establishmentData.theme),
    catalogConfiguration: getCatalogConfiguration(features, establishmentData.catalogConfiguration),
    announcements: getAnnouncements(features, establishmentData.announcements),
  };
};

export const getSubscriptionInfo = (subscription?: SubscriptionInfo): SubscriptionInfo | null => {
  if (!subscription) {
    return null;
  }
  return {
    ...subscription,
    createdAt: firestoreDateParser(subscription.createdAt),
    updatedAt: firestoreDateParser(subscription.updatedAt),
    canceledAt: firestoreDateParser(subscription.canceledAt),
    currentPeriodStartDate: firestoreDateParser(subscription.currentPeriodStartDate),
    currentPeriodEndDate: firestoreDateParser(subscription.currentPeriodEndDate),
    trialPeriodStartDate: firestoreDateParser(subscription.trialPeriodStartDate),
    trialPeriodEndDate: firestoreDateParser(subscription.trialPeriodEndDate),
  };
};

export const getDefaultWorkingDays = (): WorkingDays => {
  return {
    isActive: false,
    enableOrdersOnClosed: false,
    selectedDaysOfWeek: [1, 2, 3, 4, 5, 6, 7],
    primaryWorkingHours: {},
    secondaryWorkingHours: {},
    customizeHoursByDay: false,
    daysOfWeekConfig: {
      1: { primaryWorkingHours: {}, secondaryWorkingHours: {} },
      2: { primaryWorkingHours: {}, secondaryWorkingHours: {} },
      3: { primaryWorkingHours: {}, secondaryWorkingHours: {} },
      4: { primaryWorkingHours: {}, secondaryWorkingHours: {} },
      5: { primaryWorkingHours: {}, secondaryWorkingHours: {} },
      6: { primaryWorkingHours: {}, secondaryWorkingHours: {} },
      7: { primaryWorkingHours: {}, secondaryWorkingHours: {} },
    },
  };
};

// TODO: REMOVE
const getDeliveryType = (
  features: Feature[],
  deliveryType?: EstablishmentCheckout['deliveryType'],
): EstablishmentCheckout['deliveryType'] => {
  if (!features.includes('DELIVERY_ZONES')) {
    return DeliveryType.FIXED;
  }
  return deliveryType || DeliveryType.FIXED;
};

// TODO: REMOVE
const getPaymentMethods = (paymentMethods?: PaymentMethod[]) => {
  if (!paymentMethods) {
    return [];
  }
  return paymentMethods.map(paymentMethod => {
    if (!paymentMethod.priceModifier) {
      // Sets default priceModifier to 'none' and backfill existing records with 'extraCharge'
      paymentMethod.priceModifier = paymentMethod.extraCharge ? 'extraCharge' : 'none';
    }
    return paymentMethod;
  });
};

export const getWorkingDays = (
  features: Feature[],
  workingDays?: Establishment['workingDays'],
): Establishment['workingDays'] => {
  if (!features.includes('WORKING_HOURS') || !workingDays) {
    return getDefaultWorkingDays();
  }
  if (workingDays.enableOrdersOnClosed === undefined) {
    workingDays.enableOrdersOnClosed = true; // sets default value to true for backwards compatibility
  }
  return workingDays;
};

export const getTemporaryClosure = (
  features: Feature[],
  temporaryClosure?: Establishment['temporaryClosure'],
): Establishment['temporaryClosure'] => {
  if (!features.includes('WORKING_HOURS') || !temporaryClosure) {
    return undefined;
  }
  if (temporaryClosure.from) {
    temporaryClosure.from = firestoreDateParser(temporaryClosure.from) as Date;
  }
  if (temporaryClosure.to) {
    temporaryClosure.to = firestoreDateParser(temporaryClosure.to) as Date;
  }
  return temporaryClosure;
};

// TODO: REMOVE
const getCustomFields = (
  features: Feature[],
  customFields?: EstablishmentCheckout['customFields'],
): EstablishmentCheckout['customFields'] => {
  if (!customFields || !features.includes('CHECKOUT_CUSTOM_FIELDS')) {
    return [];
  }
  return customFields.map(customField => {
    if (!customField.fieldType) {
      customField.fieldType = 'text'; // default fieldType
    }

    if (customField['leyend']) {
      customField.legend = customField['leyend']; // fixes old data typo

      delete customField['leyend'];
    }
    return customField;
  });
};

export const getFacebookPixel = (
  features: Feature[],
  facebookPixel?: Establishment['facebookPixel'],
): Establishment['facebookPixel'] => {
  if (!features.includes('FACEBOOK_PIXEL')) {
    return undefined;
  }
  return facebookPixel;
};

export const getGoogleAnalytics = (
  features: Feature[],
  googleAnalytics?: Establishment['googleAnalytics'],
): Establishment['googleAnalytics'] => {
  if (!features.includes('GOOGLE_ANALYTICS')) {
    return undefined;
  }
  return googleAnalytics;
};

export const getGoogleTagManager = (
  features: Feature[],
  googleTagManager?: Establishment['googleTagManager'],
): Establishment['googleTagManager'] => {
  if (!features.includes('GOOGLE_TAG_MANAGER')) {
    return undefined;
  }
  return googleTagManager;
};

export const getSeoSocialMedia = (
  features: Feature[],
  seoSocialMedia?: Establishment['seoSocialMedia'],
): Establishment['seoSocialMedia'] => {
  if (!features.includes('SEO_SOCIAL_MEDIA') || !seoSocialMedia) {
    return undefined;
  }
  if (seoSocialMedia.displayShareButton === undefined) {
    seoSocialMedia.displayShareButton = true;
  }
  return seoSocialMedia;
};

export const getEnableCoupons = (
  features: Feature[],
  enableCoupons?: Establishment['enableCoupons'],
): Establishment['enableCoupons'] => {
  if (!features.includes('COUPONS')) {
    return false;
  }
  return enableCoupons || false;
};

export const getIsDigitalMenu = (
  features: Feature[],
  isDigitalMenu?: Establishment['isDigitalMenu'],
): Establishment['isDigitalMenu'] => {
  if (!features.includes('DIGITAL_MENU')) {
    return false;
  }
  return isDigitalMenu || false;
};

// TODO: REMOVE
const getRequireAddressDetails = (
  features: Feature[],
  requireAddressDetails?: EstablishmentCheckout['requireAddressDetails'],
): EstablishmentCheckout['requireAddressDetails'] => {
  if (!features.includes('ADDRESS_IN_MAP')) {
    return false;
  }
  return requireAddressDetails || false;
};

export const getTheme = (
  features: Feature[],
  theme?: Establishment['theme'],
): Establishment['theme'] => {
  if (!features.includes('THEME')) {
    return undefined;
  }
  return theme;
};

export const getCatalogConfiguration = (
  features: Feature[],
  catalogConfiguration: Establishment['catalogConfiguration'] = {
    displaySku: false,
    skuInOrderMessage: false,
  },
): Establishment['catalogConfiguration'] => {
  if (!features.includes('SKU') || catalogConfiguration.displaySku === undefined) {
    catalogConfiguration.displaySku = false;
  }
  if (catalogConfiguration.skuInOrderMessage === undefined) {
    catalogConfiguration.skuInOrderMessage = false;
  }
  return catalogConfiguration;
};

export const getAnnouncements = (
  features: Feature[],
  announcements: Establishment['announcements'],
): Establishment['announcements'] => {
  if (!features.includes('ANNOUNCEMENTS')) {
    return undefined;
  }
  return announcements;
};
