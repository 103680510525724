import { initializeApp, FirebaseApp } from '@angular/fire/app';
import { initializeAuth, Auth, indexedDBLocalPersistence } from '@angular/fire/auth';
import { getStorage, FirebaseStorage } from '@angular/fire/storage';
import { getFunctions, Functions } from '@angular/fire/functions';
import { environment } from '../../environments/environment';

let app: FirebaseApp;
let auth: Auth;
let storage: FirebaseStorage;
let functions: Functions;

export const getFirebaseApp = () => {
  if (!app) {
    app = initializeApp(environment.firebase);
  }
  return app;
}

export const getFirebaseAuth = () => {
  if (!auth) {
    auth = initializeAuth(getFirebaseApp(), { persistence: indexedDBLocalPersistence });
  }
  return auth;
}

export const getFirebaseStorage = () => {
  if (!storage) {
    storage = getStorage();
  }
  return storage;
}

export const getFirebaseFunctions = () => {
  if (!functions) {
    functions = getFunctions(getFirebaseApp(), 'southamerica-east1');
  }
  return functions;
}
